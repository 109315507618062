/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';
import './src/styles/custom.css';
import './src/styles/blobz.min.css';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import guardianQueryClient from '@src/apiFetch/guardianQueryClient';
import { AuthProvider } from '@descope/react-sdk';
import getConfig from '@src/util/getConfig';

export const wrapRootElement = ({ element }) => {
    const config = getConfig();
    console.log('descope project ID', config.descope_project_id);
    return (
        <QueryClientProvider client={guardianQueryClient}>
            <Helmet
                bodyAttributes={{ class: 'font-custom' }}
                link={[
                    {
                        rel: 'icon',
                        type: 'image/svg',
                        href: config.favicon_url,
                    },
                ]}
            >
                <script
                    type="text/javascript"
                    id="hs-script-loader"
                    async
                    defer
                    src="//js-na1.hs-scripts.com/23684445.js"
                />
                <script
                    async
                    src={`https://www.googletagmanager.com/gtag/js?id=${config.google_analytics_id}`}
                />
                <script>
                    {` window.dataLayer = window.dataLayer || []
                  function gtag() {
                    dataLayer.push(arguments)
                  }
                  gtag('js', new Date())
                  gtag('config', '${config.google_analytics_id}')`}
                </script>
            </Helmet>
            <AuthProvider projectId={config.descope_project_id}>
                {element}
            </AuthProvider>
        </QueryClientProvider>
    );
};

export const wrapPageElement = ({ element }) => (
    <>
        {element}
        <Toaster toastOptions={{ duration: 5000 }} />
    </>
);

// Prevent page scrolling on url search parameters change
export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => false;
